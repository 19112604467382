@use 'sass:map';
@use '@angular/material' as mat;
@use '@fireflysemantics/sass-logger' as logger;

@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$primary-palette: (
    50: #e8eaf7,
    100: #c6caeb,
    200: #a0a8de,
    300: #7a86d1,
    400: #5d6bc7,
    500: #4050bd,
    600: #3a48b2,
    700: #313ea6,
    800: #29349a,
    900: #1a2186,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $dark-primary-text,
        A200: white,
        A400: white,
        A700: white,
    )
);

$accent-palette: (
    50: #e1f5fd,
    100: #b3e5fa,
    200: #81d4f7,
    300: #51c3f2,
    400: #2cb6f1,
    500: #09a9ee,
    600: #059be0,
    700: #0088cc,
    800: #0077b8,
    900: #005796,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $dark-primary-text,
        A200: white,
        A400: white,
        A700: white,
    )
);

$custom-typography: mat.define-typography-config(
    $font-family: Roboto,
);

$custom-theme: mat.define-light-theme((
    color: (
        primary: mat.define-palette($primary-palette),
        accent: mat.define-palette($accent-palette),
        warn: mat.define-palette(mat.$red-palette)
    ),
    typography: $custom-typography,
    density: 0
));

@function theme-background-change($theme, $name, $value) {
    $modified-theme: $theme;
    $color: map.get($theme, color);
    $color-background: map.get($color, background);
    @if $color-background {
        $color-background: map.merge($color-background, ($name: $value));
        $color: map.merge($color, (background: $color-background));
        $modified-theme: map.merge($modified-theme, (color: $color));
    }

    $background: map.get($theme, background);
    @if $background {
        $background: map.merge($background, ($name: $value));
        $modified-theme: map.merge($modified-theme, (background: $background))
    }

    @return $modified-theme;
}

$custom-theme: theme-background-change($custom-theme, app-bar, white);

//$result: logger.pretty-map($custom-theme);
//@debug $result;

@include mat.all-component-themes($custom-theme);
@include mat.all-component-typographies($custom-typography);

html, body {
    height: 100%;
    margin: 0;

    --pmg-selected-background-color: #1A2186;
    --pmg-selected-text-color: #fff;

    --pmg-default-text-color: #424242;
    --pmg-text-color-2: rgba(55, 63, 81, 0.8);

    --pmg-empty-color: #979797;

    --pmg-link-color: #0088CC;
}

:root {
    --mat-sidenav-content-text-color: #424242;
    --mat-table-header-headline-color: #424242;
}

.pmg-primary-dark {
    color: var(--pmg-selected-background-color);
}

a {
    color: var(--pmg-link-color);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &.pmg-primary-dark {
        color: var(--pmg-selected-background-color);

        &.mat-mdc-unelevated-button.mat-primary {
            &:not(:disabled) {
                background-color: var(--pmg-selected-background-color);
                color: var(--pmg-selected-text-color);
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
}

$primary-color: mat.get-theme-color($custom-theme, primary);
$primary-dark: mat.get-theme-color($custom-theme, primary, 900);
$accent-color: mat.get-theme-color($custom-theme, accent, 700);
$warn-color: mat.get-theme-color($custom-theme, warn);
.primary {
    color: $primary-color;

    &.dark {
        color: $primary-dark;
    }
}
.accent {
    color: $accent-color;
}
.warn {
    color: $warn-color;
}

h1.page-title {
    font-weight: 700;
    font-size: 40px;
    color: var(--pmg-default-text-color);
    margin-bottom: 36px;
}

.mat-divider.page-title-divider {
    margin-bottom: 36px;
}

.mat-mdc-paginator {
    .mat-mdc-icon-button.mat-mdc-button-base {
        height: 40px;
        padding-top: 8px;

        &:first-of-type {
            padding-right: 8px;
        }

        &:last-of-type {
            padding-left: 8px;
        }
    }
}
.mat-mdc-paginator-range-actions {
    border: 1px solid #cccccc;
    border-radius: 4px;
}
.table-container {
    @at-root .table-actions-container {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }

    .filter-container {
        flex: 1;
        display: flex;
        justify-content: flex-end;

        > * {
            margin-left: 16px;
        }
    }

    .mat-mdc-paginator {
        font-size: 14px;
        display: inline-block;

        &-outer-container { // ..mat-mdc-paginator-outer-container
            width: auto;
        }

        &-container { // ..mat-mdc-paginator-container
            display: flex;
            justify-content: flex-start;
            padding-left: 0;
            width: auto;
            min-height: 42px;
        }

        &-range-label { // ..mat-mdc-paginator-range-label
            margin-right: 0;
            font-size: 14px;
        }

        &-page-size { // ..mat-mdc-paginator-page-size
            border: 1px solid #cccccc;
            border-radius: 4px;
            margin-right: 16px;

            &-label { // ..mat-mdc-paginator-page-size-label
                font-size: 14px;
                margin-left: 16px;
            }

            &:has(.mdc-text-field--focused) {
                border-color: var(--mdc-outlined-text-field-focus-outline-color);
            }
        }

        .mat-mdc-select-value {
            font-size: 14px;
        }
    }

    .mat-mdc-paginator .mdc-notched-outline {
        &__leading, &__trailing {
            border: none;
        }
    }

    .mat-mdc-form-field-flex {
        height: 40px;
    }

    .mdc-text-field, .mdc-text-field--no-label {
        .mat-mdc-form-field-infix {
            min-height: 40px;
            padding: 0;
            display: flex;
            align-items: center;

            .mat-mdc-floating-label {
                top: 20px;
            }
        }

        .mdc-line-ripple {
            display: none;
        }
    }

    .mdc-text-field--filled {
        border-radius: 4px;
        border: 1px solid #cccccc;
        transition: border-color 0.5s;

        &:not(.mdc-text-field--disabled) {
            background: #fff;

            .mat-mdc-form-field-focus-overlay {
                display: none;
            }
        }

        .mdc-floating-label {
            font-weight: 500;

            &--float-above {
                font-size: 14px;
                transform: translateY(-98%) scale(0.75);
            }
        }

        .mat-mdc-select {
            &:not(.mat-mdc-select-empty) {
                margin-top: 16px;

                .mat-mdc-select-arrow-wrapper {
                    transform: var(--mat-select-arrow-transform);
                }
            }
        }
    }
    .mat-focused {
        .mdc-text-field--filled {
            border-color: var(--mdc-outlined-text-field-focus-outline-color);
            transition: border-color 0.5s;
        }
    }

    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }

    .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
        transform: none;
    }
}

.mat-mdc-header-row {
    height: 48px;
}
.mat-mdc-header-cell {
    font-size: 16px;
}

$empty-tab-color: #979797;
.mat-mdc-tab-group.tall-tabs {
    .mat-mdc-tab {
        &.mdc-tab--active {
            z-index: 10;

            .mdc-tab__text-label {
                color: #fff;
            }

            .mdc-tab-indicator__content--underline {
                &:hover, &:focus {
                    background-color: var(--pmg-selected-background-color);
                }
                border-color: var(--pmg-selected-background-color);
            }
        }

        &.loading {
            &:not(.mdc-tab--active) {
                .mdc-tab__text-label {
                    color: $empty-tab-color;
                }
            }

            .mdc-tab-indicator__content--underline {
                border-color: $empty-tab-color;
            }
        }

        &:not(.mdc-tab--stacked) {
            height: 64px;
            border-top: 8px solid $empty-tab-color;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            background: #fff;
            margin-bottom: 2px;

            &:not(:first-child) {
                margin-left: 2px;
            }

            &:not(:last-child) {
                margin-right: 2px;
            }

            &.loading {
                border-top-color: $empty-tab-color;
            }

            &.loaded {
                border-top-color: var(--pmg-selected-background-color);
            }
        }

        .mdc-tab__text-label {
            color: var(--pmg-selected-background-color);
            max-width: 180px;
            text-wrap: wrap;
        }

        .mdc-tab-indicator__content--underline {
            border-top-width: 64px;
            border-color: var(--pmg-selected-background-color);
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        .mdc-tab__content {
            margin-top: -6px;
        }
    }
}

// overrides for mdc-button inside sidebar nav
sidebar-nav {
    .mdc-button__label {
        line-height: 18px;
    }
}

.no-data {
    background: #fff;
    padding: 56px;
    font-size: 24px;
    color: var(--pmg-empty-color);
    line-height: 30px;
    text-align: center;
}

// @see https://github.com/angular/components/issues/26094#issuecomment-1490245622
@for $i from 0 through 24 {
    .mat-mdc-card.mat-elevation-z#{$i} {
        @include mat.elevation($i);
    }
}
